import React from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import styled from "styled-components"

const Wrapper = styled.div`
  padding-top: 30rem;
`

const NotFoundPage = () => {

  return (
    <Layout hideFooter>
      <SEO title='404: Not found' />
      <Wrapper>
        <h1>Sidan kunde tyvärr inte hittas</h1>
        <p>Gå tillbaka till startsidan och försök igen..</p>
      </Wrapper>
    </Layout>
  )
}

export default NotFoundPage
